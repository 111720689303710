#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    height: 100px;
    padding-top: 40px;
}

#left {
    /* max-width: 170px; */
    /* width: 100%; */
    height: 100%;
}

#logo {
    height: 100%;
}

.dark_mode .link {
    color: var(--primary-color);
}

.link {
    text-decoration: none;
    color: white;
    font-size: 14px;
    line-height: 20px;
}

#middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
}

.right {
    margin-top: 0;
}

.right>* {
    font-size: 12px;
}


@media (max-width: 956px) {
    #header {
        padding-top: 20px
    }

    .right {
        display: none;
    }
}