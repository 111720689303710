.hero_banner {
    width: 100%;
    background-color: var(--secondary-color);
    padding-block: 10px 20px;
    color: white;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 2;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.supporting_grid {
    /* display: grid;
    grid-template-columns: repeat(2, 600px); */

    display: flex;
    flex-direction: row;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
    gap: 0px;
    width: 100%;
    min-height: 80px;

}

#overline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

#more_button {
    text-decoration: underline;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
}

.supporting_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.agencies_container {
    gap: 24px;
    padding-right: 26px;
}

.sponsors_container {
    gap: 18px;
    border-left: 1px solid white;
    padding-left: 26px;
    /* flex-grow: 1; */
}

.agency_img {
    width: 62px;
    object-fit: contain;
    max-height: 80px;

    /* height: 100%;
    object-position: center; */
}

.sponsor_img {
    max-height: 80px;
}

#firstnet_logo {
    width: 296px;
}


@media (max-width: 1273px) {
    .supporting_grid {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .sponsors_container {
        border-left: none;
    }

    .supporting_container {
        width: 100%;
        /* justify-content: space-evenly; */
        justify-content: center;
        flex-wrap: wrap;
    }

}