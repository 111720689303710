.hero_carousel {
    position: relative;
    background-color: var(--primary-color);
    /* height: calc(100vh - 140px); */
    /* height: 100%; */
    min-height: fit-content;




}

.carousel_page {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    /* height: calc(100vh - 140px); */


}

.carousel_page:not(:last-of-type) {
    opacity: 1;
}

.carousel_page:last-of-type {
    z-index: 2;
}

.carousel_page[data-index="1"] {
    /* tallest page */
    position: relative;
}

.carousel_page.fading_in_page {
    /* position: relative; */
    animation: FadeIn 1s ease forwards;

}

@keyframes FadeIn {
    0% {
        opacity: 0.01;
    }

    /* 99% {
      opacity: 0;
    } */

    100% {
        opacity: 1;
    }
}

.buttons_container {
    position: absolute;
    bottom: 35px;
    left: 50%;
    translate: -50%;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.carousel_button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid white;
    cursor: pointer;
}

.carousel_button.active_button {
    background-color: transparent;
}