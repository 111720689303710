.hero_images_overflow_container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-top: 110px; */
}


.hero_images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: min(110vw, 1440px);
}

.hero_images img {
    object-fit: cover;
    border-radius: 16px;
}