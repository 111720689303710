.testimonial_carousel {
    --transition-time: 200ms;

    /* height: 300px; */
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 60px;
}

.left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    width: 270px;
}

.heading {
    margin-top: 15px;
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
    color: var(--primary-color);
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.button {
    border: 0;
    background-color: #F3F3F5;
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.right_overflow_container {
    overflow: hidden;
    height: 360px;
}

.testimonials_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 26px; */
    width: fit-content;
}

.testimonials_container.transition {
    transition: all 0.2s ease;
}


.testimonial_card:not(:last-of-type) {
    margin-right: 26px;
}

.testimonial_card {
    height: 340px;
    padding: 24px 24px 30px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    color: 0.2s(--primary-color);
    background-color: #F3F3F5;
    border-radius: 16px;
}

.testimonial_card:first-child {
    max-width: 100vw;
    min-height: fit-content;
}

.testimonial_card>* {
    opacity: 1;
    transition: opacity 0.2s ease;
}

.card_top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 17px;
}

.card_top p {
    line-height: 20px;
}

.card_name {
    line-height: 24px;
}

.card_title {
    font-size: 14px;
    line-height: 18px;
}

@media (max-width: 830px) {

    /* small */

    .testimonial_carousel {
        grid-template-columns: 1fr;
    }

    .right_overflow_container {
        height: max-content;
    }
}