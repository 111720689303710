.span {
    position: relative;
}

.text {
    position: relative;
    z-index: 3;

}

.underline {
    z-index: 2;
    position: absolute;
    translate: -50%;
    left: 50%;
    top: calc(100% - 20px);
    width: calc(100% + 24px);
    height: 30px;
    object-fit: fill;
    object-position: top;
}