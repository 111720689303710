.page {
    position: relative;
    /* height: calc(100vh - 140px); */
    /* height: fit-content; */
    /* min-height: 600px; */
    min-height: calc(100vh - 140px);
    height: 100%;

    padding-block: 160px 120px;



    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0px;
    z-index: 1;
}


.description {
    line-height: 20px;
    max-width: 634px;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #002859 0%, #1D326080 50%);
}

.content {
    position: relative;
    z-index: 3;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;


}

.heading>span {
    color: var(--secondary-color);
}

.links_container {
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
}