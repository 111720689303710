.arrow_accordion {
    display: grid;
    grid-template-columns: 20fr 60fr 20fr;
}

.arrow_accordion.dark_mode {
    color: white;
}

.row {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1/4;
    border-bottom: 1px solid #aeb4c3;
    padding-block: 25px;
    color: var(--keel-navy);
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.row:first-of-type {
    border-block: 1px solid #aeb4c3;
}

.question {
    font-weight: 500;
    line-height: 24px;
}

.row.active .question {
    font-weight: 600;
}

.answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    transition: padding-top 500ms ease, opacity 500ms ease;
    cursor: text;
}

.row.active .answer {
    opacity: 1;
    max-height: max-content;
    padding-top: 20px;
}

.right {
    margin-left: auto;
    border: none;
}

.right {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--primary-color);
}

.row.active .right {
    background-color: var(--secondary-color);
}

.star_svg {
    fill: white;
}

.row.active .star_svg {
    fill: var(--secondary-color);
}

.arrow_svg {
    rotate: 0;
    stroke: white;
    transition: rotate 500ms ease;
}


.row.active .arrow_svg {
    rotate: 180deg;
}