.icon {
    width: 68px;
    height: 68px;
    object-fit: contain;
    margin-bottom: 10px;
}

.icon_tile {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    padding: 30px;
    border-radius: 16px;
    border: 1px solid #5C7087;
    width: 280px;
    height: 280px;

    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
}

.description {
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 620px) {
    .icon {
        width: 44px;
        height: 44px;
        object-fit: contain;
    }

    .title {
        font-size: 20px;
        line-height: 24px;
    }

    .icon_tile {
        width: 180px;
        min-height: 180px;
        padding: 20px;
    }
}