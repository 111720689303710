.logo_container {
    position: relative;

}

.logo {
    height: 54px;
}

.tooltip {
    position: absolute;
    /* bottom: 0; */
    text-align: center;
    translate: -50%;
    left: 50%;
    /* max-width: 100%; */

    padding: 4px 8px;
    background-color: #fff;
    border-radius: 4px;
    color: var(--primary-color);
    font-size: 12px;
    box-shadow: 4px 4px 10px #0000001a;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2ms ease-out;
}

.logo_container:hover .tooltip {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
}