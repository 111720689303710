#home_modal_backdrop {
    position: fixed;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000008c;
}

#home_modal {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 964px;

    max-height: 90vh;
    overflow-y: scroll;

}

.hide_scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.hide_scrollbar::-webkit-scrollbar {
    display: none;
}

#graphic_container {
    width: 50%;
    min-height: 100%;
    position: relative;
    border-radius: 20px 0 0 20px;
    overflow: hidden;

    position: sticky;
    top: 0;
}

#video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#content {
    padding: 40px;
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;

    position: relative;
}

.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: #00285966;
}

#heading_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    max-width: 540px;
}

#heading_container h1 {
    font-size: 40px;
    line-height: 44px;
}

#heading_container p {
    color: #181818;
}

.cell {
    padding: 12px 26px;
    border-radius: 16px;
    border: 1px solid #D9D9D9;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;

    font-size: 20px;
    width: 270px;
}

#total_cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;

    text-align: center;

    font-size: 16px;
    padding-block: 15px;
    margin-block: 15px 10px;
    width: 100%;
}

.cell_text_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
}

.large_logo {
    height: 48px;
}

.small_logo {
    height: 26px;
}

#cells_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.red {
    color: var(--secondary-color);
}

.number {
    font-size: 50px;
}

.high_asterisk {
    position: relative;
    top: -0.1em;
}

#disclaimer {
    color: #98a1b2;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
}

.contact_links {
    align-self: center;
    padding-bottom: 20px;
}

#small_logos_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    justify-items: center;
    gap: 7px;

    margin-top: 15px;
}

#large_logos_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 7px;

    width: 100%;

    margin-top: 20px;
}

#close_button {
    position: absolute;
    border: none;
    background: none;
    width: 30px;
    height: 30px;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

@media (max-width: 1060px) {
    #cells_container {
        width: 100%;
    }

    #graphic_container {
        display: none;
    }

    #home_modal {
        width: fit-content
    }

    #grid_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 0px;
    }

    .cell,
    #total_cell_container {
        width: 100%;
    }
}

@media (max-width: 670px) {
    .cell {
        align-items: center;
        width: 100%;
        max-width: 270px;

        margin-inline: auto;
    }

    #content {
        padding: 12px;
    }

    #heading_container {
        text-align: center;
        align-items: center;
        padding-top: 30px;
    }

    #close_button {
        top: 20px;
        right: 20px;
    }


    #cells_container {
        grid-template-columns: 1fr;
    }

    #home_modal {
        max-width: 90vw;
    }

    #large_logos_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    #small_logos_container {
        width: fit-content;
    }

}