* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #002859;
  --secondary-color: #fE111A;
  --tertiary-color: #181818;
  --padding-inline: 70px;

  font-family: 'Inter', sans-serif;
  color: var(--primary-color);

}

button:focus-visible {
  outline-offset: 1px;
  outline: rgb(0, 95, 204) 1px solid;
}

.link-button {
  background-color: var(--secondary-color);
  display: flex;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 28px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.link-button.outline {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 11px 24px;
}

.link-button.outline.dark-mode {
  color: white;
  border: 1px solid white;
}

.cap-width {
  max-width: calc(1200px + 2 * var(--padding-inline));
  margin-inline: auto;
  width: 100%;
  padding-inline: var(--padding-inline);
  /* box-sizing: content-box; */
}

h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 60px;
}

h2 {
  font-size: 40px;
  line-height: 44px;
}

.invalid-input {
  border: 2px solid var(--secondary-color) !important;
  margin: 0 !important;
}

@media (max-width: 956px) {
  :root {
    --padding-inline: 20px
  }
}

@media (max-width: 670px) {
  h1 {
    font-size: 48px;
    line-height: 52px;
  }

  h2 {
    font-size: 32px;
    line-height: 38px;
  }

}