.header {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50%;
    z-index: 5;
}

#introduction_section {
    padding-block: 100px;
}

#introduction_section_content {
    display: grid;
    grid-template-columns: repeat(auto-fit, min(580px, calc(100vw - 2 * var(--padding-inline))));
    justify-content: center;
    row-gap: 80px;
}

#introduction_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.heading_body {
    color: var(--tertiary-color);
    max-width: 485px;
}

#introduction_body>strong {
    color: var(--secondary-color);
}

#introduction_graphic_img {
    width: 100%;
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #002859CC;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    opacity: 0.8;
    z-index: 1;
}

#tiles_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.tiles_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

#why_section {
    padding-block: 50px 80px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#why_section_content {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

#why_section_heading {
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

#testimonials_section {
    padding-block: 140px;
}

#benefits_container {
    margin-block: 70px;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, 580px);
    justify-items: center;
    justify-content: center;
    gap: 20px;
}


.benefits_container_row {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    width: 580px;
    max-width: calc(100vw - 2 * var(--padding-inline));
    justify-content: center;
}

.benefit_card_icon {
    height: 82px;
}

#faq_section {
    margin-top: 190px;
    padding-block: 245px 210px;
    background-color: var(--primary-color);
    position: relative;
}

#faq_section_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.faq_img {
    min-width: 564px;
    max-width: 564px;
    height: 376px;
}

.faq_images_container {
    width: 100%;
    position: absolute;
    top: 0;
    translate: 0 -50%;
}

#faq_heading {
    color: white;
    font-weight: 600;
    margin-bottom: 50px;
}

@media (max-width: 956px) {
    .faq_images_container {
        visibility: hidden;
    }

    #faq_section {
        padding-block: 100px;
        margin-top: 0;
    }
}