#heading_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-align: center;
    padding-block: 100px 90px;
}

#heading {
    max-width: 795px;
}

.heading_body {
    color: var(--tertiary-color);
    max-width: 600px;
}

#form_section {
    margin-bottom: 190px;
}